<template>
    <v-container style="background:white;">







<!--star of events-->


      



<br>
<br>






        <!--end of events-->




























        <v-layout row wrap>
            <div style="background:#ff6600;width:5px;height:30px;"></div>
            <span style="font-size:22px;margin-right:10px;color:gary;font-weight: 700;"> المراكز</span>
        </v-layout>
        <br>


        <v-layout row wrap class="hidden-md-and-down">
            <v-flex xs12 md10 sm10 class="up_event_text">
                <span>{{upcomingEvent}}
                    <br>
                    {{upcomingEvent}}
                </span>
            </v-flex>


            <div class="hidden-md-and-down">
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

            </div>
            <v-flex xs12 md2 sm2 class="hidden-md-and-down">
                <v-btn class="up_event_btn" to="/search/all/all/no"> عرض الكل</v-btn>
            </v-flex>
        </v-layout>

        <br><br>
        <v-layout row wrap>



            <!-- <v-flex xs12 md4 sm4 xl3 v-for="item in priority_items.slice(0,3)" :key="item.id" pa-3>
                <router-link :to="{ name: 'item', params: { id: item.id } }">


                    <v-card class="up_event_card"
                        :style="[item.images.length == 0 ? {'background': 'url(/assets/defaultHeader.jpg)'} :  {'background': 'url('+Url+'/images/'+item.images[0].image_url+') '} ]">

                        <v-layout column wrap style="height:100%">
                            <v-row v-if="item.offer.length !== 0">
                                <div class="up_event_card_date">
                                    <span>خصم {{item.offer[0].discount_percentage}} ٪ </span><br>
                                    <span>متبقي</span> <br>
                                    <span class="year">{{getOfferDay(item.offer[0].offer_to_date)}}</span> <br>
                                    <span>يوم</span>
                                </div>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-container fluid class="up_event_card_bottom_layout">

                                <div style="d-flex space-between">

                                    <div class="d-inline">
                                        <span class="up_event_card_text1">{{item.owner.owner_barnd_name}} |
                                            {{item.item_name}}</span>
                                    </div>

                                    <div class="d-inline float-left">


                                        <v-btn class="booking-btn" :to="'/item/'+item.id">
                                            <v-icon right="">fas fa-bookmark</v-icon>
                                            طلب الحجز
                                        </v-btn>

                                    </div>

                                    <div class="d-block">
                                        <span class="up_event_card_text2"> <i class="fas fa-map-marker-alt"></i>
                                          

                                            {{item.Address[0].province.province_name}}
                                        </span>
                                    </div>



                                </div>


                            </v-container>

                        </v-layout>

                    </v-card>

                </router-link>
            </v-flex> -->

            <v-flex xs12 md4 sm4 xl3 v-for="item in items.slice(0,6)" :key="item.id" pa-3>

                <router-link :to="{ name: 'item', params: { id: item.id } }">

                    <v-card class="up_event_card"
                        :style="[item.images.length > 0 ? {'background': 'url('+Url+'/images/'+item.images[0].image_url+') '} : {'background': 'url(/assets/defaultHeader.jpg)'}]">

                        <v-layout column wrap style="height:100%">
                            <v-row v-if="item.offer.length !== 0">
                                <div class="up_event_card_date">
                                    <span>خصم {{item.offer[0].discount_percentage}} ٪ </span><br>
                                    <span>متبقي</span> <br>
                                    <span class="year">{{getOfferDay(item.offer[0].offer_to_date)}}</span> <br>
                                    <span>يوم</span>
                                </div>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-container fluid class="up_event_card_bottom_layout">

                                <div style="d-flex space-between">

                                    <div class="d-block">

                                        <span class="up_event_card_text1">{{item.owner.owner_barnd_name}} |
                                            {{item.item_name}}</span>
                                    </div>

                                    <div class="d-inline">
                                        <span class="up_event_card_text2"> <i class="fas fa-map-marker-alt"></i>
                                            {{item.Address[0].province.province_name}} </span>
                                    </div>

                                    <div class="d-inline float-left">


                                        <v-btn class="booking-btn" :to="'/item/'+item.id">
                                            <v-icon right="">fas fa-bookmark</v-icon>
                                            طلب الحجز
                                        </v-btn>

                                    </div>


                                </div>


                            </v-container>

                        </v-layout>

                    </v-card>

                </router-link>
            </v-flex>

            <v-flex xs12 md2 sm2 class="hidden-lg-and-up">
                <v-btn class="up_event_btn" to="/search/all/all/no"> عرض الكل</v-btn>
            </v-flex>
        </v-layout>





        <!--Ads-->
        <v-layout wrap row v-if="advertisings.length>0">

            <v-container style="max-width: 895px !important">
                <div class="ads_title">
                    الاعلانات
                </div>

                <FlickityAds class="FlickityAds" ref="FlickityAds" :options="AdsflickityOptions">



                    <!-- <div class="lib_part" data-flickity='{ "freeScroll": true }'> -->
                    <!-- <router-link :to="{ name: 'ownerItems', params: {id:item.advertisingable_id} }"
                        v-for="(item, i) in advertisings" :key="i" style="left:0 !important"> -->
                    <div class="carousel-Ads" v-for="(item, i) in advertisings" :key="i">
                        <router-link :to="{ name: 'ownerItems', params: {id:item.advertisingable_id} }">
                            <v-card style="width:100%;height:100%">


                                <div style="width:100%;height:80%;">
                                    <v-img style="width:100%;height:100%"
                                        v-bind:src="Url+'/images/'+item.image[0].image_url">
                                    </v-img>
                                </div>

                                <div style="    height: 17%;
    position: absolute;
        bottom: 25%;
    color: #ffff;
    width: 100%;
    background-color: rgb(0 0 0 / 39%);
    text-align: center;
    font-weight: bold;
    padding-top: 10px;">{{item.adv_description}}</div>
                            </v-card>

                        </router-link>



                    </div>
                    <!-- </router-link> -->
                    <!-- </div> -->
                </FlickityAds>


                <!-- <v-carousel  height="280" hide-delimiters>
                    <router-link :to="{ name: 'ownerItems', params: {id:item.advertisingable_id} }"
                        v-for="(item, i) in advertisings" :key="i">
                        <v-carousel-item   :src="Url+'/images/'+item.image[0].image_url"
                            reverse-transition="fade-transition" transition="fade-transition">




                        </v-carousel-item>
                    


    <div  class="slider_jum" style="height: 100px !important;
    width: 200px !important;
    opacity: 0.74;
    float: left;
    position: relative;
   
    text-align: center;
" light color="#fff">
                                        <p class="slider_text gef">{{item.title}} </p>
                                    </div>
                                    {{item.title}}
                    </router-link>
                    
                </v-carousel> -->
            </v-container>

        </v-layout>









        <br>
        <br>
        <br>


        <v-layout row wrap>
            <div style="background:#ff6600;width:5px;height:30px;"></div>
            <span style="font-size:22px;margin-right:10px;color:gary;font-weight: 700;"> العروض المضافة مؤخرا </span>
        </v-layout>
        <br>


        <v-layout row wrap class="hidden-md-and-down">
            <v-flex xs12 md10 sm10 class="up_event_text">
                <span>{{upcomingEvent}}
                    <br>
                    {{upcomingEvent}}
                </span>
            </v-flex>



            <div class="hidden-md-and-down">
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

            </div>
            <v-flex xs12 md2 sm2 class="hidden-md-and-down">
                <v-btn class="up_event_btn" to="/offers"> عرض كل العروض </v-btn>
            </v-flex>
        </v-layout>

        <br><br>
        <v-layout row wrap>


            <v-flex xs12 md4 sm4 xl3 v-for="item in offers" :key="item.id" pa-3>
                <router-link :to="{ name: 'item', params: { id: item.id } }">

                    <v-card class="up_event_card"
                        :style="[item.images.length == 0 ? {'background': 'url(/assets/defaultHeader.jpg)'} :  {'background': 'url('+Url+'/images/'+item.images[0].image_url+') '} ]">

                        <v-layout column wrap style="height:100%">
                            <v-row>
                                <div class="up_event_card_date">
                                    <span>خصم {{item.offer[0].discount_percentage}} ٪ </span><br>
                                    <span>متبقي</span> <br>
                                    <span class="year">{{getOfferDay(item.offer[0].offer_to_date)}}</span> <br>
                                    <span>يوم</span>
                                </div>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-container fluid class="up_event_card_bottom_layout">

                                <div style="d-flex space-between">

                                    <div class="d-block">
                                        <span class="up_event_card_text1">{{item.owner.owner_barnd_name}} |
                                            {{item.item_name}}</span>
                                    </div>

                                    <div class="d-inline">
                                        <span class="up_event_card_text2"> <i class="fas fa-map-marker-alt"></i>
                                            {{item.Address[0].province.province_name}} </span>
                                    </div>

                                    <div class="d-inline float-left">


                                        <v-btn class="booking-btn" :to="'/item/'+item.id">
                                            <v-icon right="">fas fa-bookmark</v-icon>
                                            طلب الحجز
                                        </v-btn>

                                    </div>

                                    <!-- <div class="d-block">
                                        <span class="up_event_card_text2"> <i class="fas fa-map-marker-alt"></i>
                                            {{item.Address.province.province_name}} </span>
                                    </div> -->



                                </div>


                            </v-container>

                        </v-layout>

                    </v-card>

                </router-link>
            </v-flex>

            <v-flex xs12 class="hidden-md-and-down">
                <v-btn class="up_event_btn" to="/offers"> عرض كل العروض </v-btn>
            </v-flex>
        </v-layout>







    </v-container>

</template>

<style>
    .FlickityAds .flickity-page-dots .dot {

        display: none;

    }


    .carousel-Ads {
        width: 100%;
        /* full width */
        height: 300px;
        /* height of carousel */

    }
</style>
<script>
    const axios = require('axios');
    import FlickityAds from 'vue-flickity'
    export default {
        data() {
            return {

                page: 1,
                offers: [],
                long: '',
                lat: '',
                AdsflickityOptions: {
                    initialIndex: 3,
                    prevNextButtons: true,
                    pageDots: true,
                    wrapAround: true

                    // any options from Flickity can be used
                },
                advertisings: [],
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4',
                ],
                slides: [{
                        src: 'https://www.aldiyaar.com/wp-content/uploads/2020/06/%D8%B9%D9%8A%D8%A7%D8%AF%D8%A7%D8%AA-%D8%AA%D8%B7%D9%85%D9%86.jpg',
                    },
                    {
                        src: 'https://3orod.waselh.com/wp-content/uploads/2012/06/dental.png',
                    },
                    {
                        src: 'https://mostaql.hsoubcdn.com/uploads/739301-7tb0C-1595203672-5f14e05886752.png',
                    },
                    {
                        src: 'https://www.alsoouq.com/wp-content/uploads/2017/07/2-5.jpg',
                    },
                    {
                        src: 'https://cdn-cms.f-static.net/uploads/2284822/800_5d077b1fbf0f3.jpeg',
                    },

                ],




                priority_items: [],
                items: [

                ],
                search_priority: [],
                upcomingEvent: '',
                search_history: this.$cookies.get('search_history'),
            }
        },
        mounted() {

            this.getOwnersAdvertising();
            this.getlast_items();
            this.getlastoffers();
        },
        components: {

            FlickityAds,

        },

        filters: {
            SubItemDisc: function (string) {
                if (string != null) {
                    return string.substring(0, 30) + ' ... ';
                }

            },
        },
        methods: {
            getOwnersAdvertising() {
                var url = "/v2/Advertising/client/getOwners";
                this.$http.get(url).then(response => {
                    this.advertisings = response.data.data;



                }).catch(error => {
                    error

                });


            },

            getlast_priority(subcat_id) {

                subcat_id
                // subcat_id,
                // function mode(array) {
                //     if (array.length == 0)
                //         return null;
                //     var modeMap = {};
                //     var maxEl = array[0],
                //         maxCount = 1;
                //     for (var i = 0; i < array.length; i++) {
                //         var el = array[i];
                //         if (modeMap[el] == null)
                //             modeMap[el] = 1;
                //         else
                //             modeMap[el]++;
                //         if (modeMap[el] > maxCount) {
                //             maxEl = el;
                //             maxCount = modeMap[el];
                //         }
                //     }
                //     // alert(maxEl);
                //     return maxEl;
                // }

                // var sub_cat = this.mode(this.search_history.sub_cats_id);
                // this.$http.get("/v2/items/search?filter[sub_category.id]=" + sub_cat).then(response => {

                //     this.priority_items = response.data.data;
                //     console.log(this.priority_items);


                // })



            },

            getlast_items() {
                var geolocation = {};
                getLocation();
                //    alert('sds');
                let self = this;

                function printLocation() {
                    if (typeof geolocation.lat === "undefined" || typeof geolocation.long === "undefined") {
                        //alert('asa');
                    } else {
                        //  alert('11'); 
                        // if(items.length===0)
                        // {
                        axios({
                                method: 'get',
                                url: "v2/items/HomeItems?sort=distance",
                                headers: {
                                    long: geolocation.long,
                                    lat: geolocation.lat
                                }

                            })
                            .then(response => {
                                self.items = response.data.data;




                            })

                        //  }


                    }
                }

                function getLocation() {
                    axios({
                            method: 'get',
                            url: "v2/items/HomeItems?sort=-id",

                        })
                        .then(response => {

                            self.items = response.data.data;


                        })
                    // If the user allow us to get the location from the browser
                    if (window.location.protocol == "http:" && navigator.geolocation)
                        navigator.geolocation.getCurrentPosition(function (position) {
                            //  alert('as22a');
                            geolocation["lat"] = position.coords.latitude;
                            geolocation["long"] = position.coords.longitude;
                            //    alert('as22a');
                            printLocation(); // Second time, will be return the location correctly
                        });
                    else {

                        // alert('a1sa');
                        axios({
                                method: 'get',
                                url: "v2/items/HomeItems?sort=-id",

                            })
                            .then(response => {

                                self.items = response.data.data;


                            })
                    }


                }



                //  getLocation();


            },


            getlastoffers() {

                this.$http.get("/v2/items/search?filter[Getoffers]=1&sort=-id").then(response => {

                    this.offers = response.data.data;


                })
            },

            getOfferDay(second) {

                var second2 = this.formatDate(second);
                var first = new Date();
                first = this.formatDate(first);
                return this.datediff(this.parseDate(first), this.parseDate(second2));



            },
            getYear(date) {
                var year = date.split("-")[0]

                return year;
            },
            getMonth(date) {
                var month = date.split("-")[1]
                var months = ["يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
                    "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
                ];
                return months[month - 1];
            },
            getDay(date) {

                var day = date.split("-")[2].split(" ")[0]

                return day;
            },

            formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [month, day, year].join('/');
            },

            parseDate(str) {
                var mdy = str.split('/');
                return new Date(mdy[2], mdy[0] - 1, mdy[1]);
            },

            datediff(first, second) {
                // Take the difference between the dates and divide by milliseconds per day.
                // Round to nearest whole number to deal with DST.
                return Math.round((second - first) / (1000 * 60 * 60 * 24));


            },

        }

    }
</script>