<template>
  <v-layout column="" wrap>

    <!-- home search component -->
    <v-flex>
      <home style="padding-bottom:100px"></home>
    </v-flex>
    <v-divider></v-divider>

    <!-- category of booking  component -->
    <v-flex style="padding-top:50px">
      <v-container style="background:white;">
        <v-layout column wrap>
          <v-layout row wrap>
            <div style="background:#ff6600;width:5px;height:30px;"></div>
            <span style="font-size:22px;margin-right:10px;color:gary;font-weight: 700;"> الاقسام</span>
          </v-layout>
          <br>
          <v-row justify="center">

            <v-flex xs6 md3 xl2 v-for="item in categores" :key="item.id" pa-3>
              <router-link :to="{ name: 'itemsByCatId', params: { id: item.id } }">
                <v-card v-if="item.images !==null"
                  :style="'width:90%;background-image:url('+Url+'/images/'+item.images.image_url+') ;    background-size: cover;'"
                  dark="" class="cat_img_home">
                  <!-- <v-row class="mb-10" style="padding-top:10px">
                <v-icon style="width:100%" size="123"> {{item.icon}}</v-icon>
              </v-row> -->
                  <v-spacer></v-spacer>
                  <v-row justify="center" style="background: rgb(0 0 0 / 52%);
    margin: 0px;
    position: relative;
    top: 80%;">
                    <v-card-text primary-title style="text-align:center;font-size:22px;font-weight:bold">
                      {{item.name}}
                    </v-card-text>
                  </v-row>
                </v-card>
              </router-link>
            </v-flex>
          </v-row>

        </v-layout>
      </v-container>
    </v-flex>
    <v-divider></v-divider>
    <!-- schedual  the booking component -->
    <v-flex>
      <schedual></schedual>
    </v-flex>

    <v-divider></v-divider>
    <!-- last newscomponent -->
    <v-flex style="padding-top:50px">
      <events></events>
    </v-flex>

    <!-- <v-flex style="padding-top:50px">
      <v-container style="background:white">


        <div class="d-flex justify-center mb-6" style="font-size:22px;color:gary;font-weight: 700;">
          <div style="background:#ff6600;width:5px;height:30px;"></div>

          <span style="padding-right:10px"> حمل تطبيق تكتاتي على
            اندرويد او الايفون</span>
        </div>

        <v-row class="hidden-lg-and-up ">

       
          <div class="d-block" style="width:100%;height:100px">
            <a href="https://play.google.com/store/apps/details?id=com.enjaz.ehjizly_2" target="_blank">
              <v-img src="google.png" style="width:100%;height:100%"></v-img>
            </a>
          </div>

          <div class="d-block" style="width:100%;height:100px;padding-top:15px;">

            <a href="https://play.google.com/store/apps/details?id=com.enjaz.ehjizly_2">
              <v-img src="apple.png" style="width:100%;height:100%"></v-img>
            </a>

          </div>


    
        </v-row>


        <div class="hidden-md-and-down ">

          <div class="hidden-md-and-down d-flex justify-center mb-6 ">


            <div class="d-inline" style="width:370px;height:100px">
              <a href="https://play.google.com/store/apps/details?id=com.enjaz.ehjizly_2" target="_blank">
                <v-img src="google.png" style="width:100%;height:100%"></v-img>
              </a>
            </div>


            <div class="d-inline" style="width:370px;height:100px">
              <a href="https://play.google.com/store/apps/details?id=com.enjaz.ehjizly_2">
                <v-img src="apple.png" style="width:100%;height:100%"></v-img>
              </a>
            </div>


          </div>

        </div>





      </v-container>
    </v-flex> -->







    <!-- <v-flex style="padding-top:50px">
      <v-container style="background:white">


        <div class="d-flex justify-center mb-6" style="font-size:22px;color:gary;font-weight: 700;">
          <div style="background:#ff6600;width:5px;height:30px;"></div>

          <span style="padding-right:10px"> اذا كنت صاحب مركز او عياده حمل تطبيق اداره تكتاتي وقم برفع بيانات مركزك
            واستلام طلبات حجوزات الزبائن بكل سهوله </span>
        </div>

        <v-row class="hidden-lg-and-up ">

        
          <div class="d-block" style="width:100%;height:100px">
            <a href="https://play.google.com/store/apps/details?id=com.ahjezely.ahjezly_owner" target="_blank">
              <v-img src="google.png" style="width:100%;height:100%"></v-img>
            </a>
          </div>

          <div class="d-block" style="width:100%;height:100px;padding-top:15px;">

            <a href="https://play.google.com/store/apps/details?id=com.ahjezely.ahjezly_owner">
              <v-img src="apple.png" style="width:100%;height:100%"></v-img>
            </a>

          </div>


       
        </v-row>


        <div class="hidden-md-and-down ">

          <div class="hidden-md-and-down d-flex justify-center mb-6 ">


            <div class="d-inline" style="width:370px;height:100px">
              <a href="https://play.google.com/store/apps/details?id=com.ahjezely.ahjezly_owner" target="_blank">
                <v-img src="google.png" style="width:100%;height:100%"></v-img>
              </a>
            </div>


            <div class="d-inline" style="width:370px;height:100px">
              <a href="https://play.google.com/store/apps/details?id=com.ahjezely.ahjezly_owner">
                <v-img src="apple.png" style="width:100%;height:100%"></v-img>
              </a>
            </div>


          </div>

        </div>





      </v-container>
    </v-flex> -->


    <!-- <v-flex style="padding-top:50px">
      <comshared></comshared>
    </v-flex> -->



    <!-- send msg to us  component -->
    <v-flex>
      <sendmsg></sendmsg>
    </v-flex>

    <v-flex>






    </v-flex>


  </v-layout>


</template>
<script>
  import home from './sub_components/home1.vue'
  import schedual from './sub_components/schedual'
  import events from './sub_components/events'

  //import counts from './sub_components/counts'
  import sendmsg from './sub_components/sendmsg-layout'
  // import comshared from './sub_components/company_shared'

  export default {
    name: 'App',

    data() {
      return {
        categores: [],
        url: "http://127.0.0.1:9999/",
        card_back: ["background: linear-gradient(to right, #2193b0,#6dd5ed);",
          "background: linear-gradient(to right, #FF0099,#493240);",
          "background: linear-gradient(to right, #f12711,#f5af19);",
          "background: linear-gradient(to right, #FF416C,#FF4B2B);",
          "background: linear-gradient(to right, #DA4453,#89216B);",
          "background: linear-gradient(to right, #6D6027,#D3CBB8);",
          "background: linear-gradient(to right, #FF0099,#493240);",
          "background: linear-gradient(to right, #DA4453,#89216B);",
          "background: linear-gradient(to right, #6D6027,#D3CBB8);",
          "background: linear-gradient(to right, #FF416C,#FF4B2B);",
          "background: linear-gradient(to right, #f12711,#f5af19);",
          "background: linear-gradient(to right, #2193b0,#6dd5ed);",
          "background: linear-gradient(to right, #2193b0,#6dd5ed);",
          "background: linear-gradient(to right, #2193b0,#6dd5ed);",
          "background: linear-gradient(to right, #2193b0,#6dd5ed);",
          "background: linear-gradient(to right, #2193b0,#6dd5ed);",
          "background: linear-gradient(to right, #2193b0,#6dd5ed);",
          "background: linear-gradient(to right, #2193b0,#6dd5ed);",
          "background: linear-gradient(to right, #2193b0,#6dd5ed);",
        ],
        items: [' الدكتور محمد علي اختصاص قلب', ' الدكتور محمد علي اختصاص قلب', ' الدكتور محمد علي اختصاص قلب',
          ' الدكتور محمد علي اختصاص اذن و انف و حنجرة '
        ],
        upcomingEvent: 'انشاء مركز تخصصي في الحارثية قرب .. وافتتاح قاعة مناسبات فرع الجادرية قرب جامعة بغداد و افتتحاح موللا الربيعي في منطقة زيونة',
        icons: [
          'fab fa-facebook',
          'fab fa-twitter',
          'fab fa-google-plus',
          'fab fa-linkedin',
          'fab fa-instagram',
        ],
      }
    },
    methods: {
      getCategory() {


        this.$http.get("v2/Categories").then(response => {
          this.categores = response.data.data;




        })
      },
      al() {

      }
    },

    mounted() {

      this.getCategory();
    },

    components: {
      schedual,
      events,
      home,
      sendmsg,
      // comshared

    }

  };
</script>