<template>
<div>
        <!-- <v-layout column style="background:#161616;color:#ff6600;padding:10px" v-if="enable==true">
        <v-container style="color:#ff6600;">





           
            <v-layout row wrap>
                <div style="background:#ff6600;width:5px;height:20px;margin:auto"></div>
                <span style="font-size:22px;margin-right:10px">جدول حجوزاتي</span>
                <v-spacer></v-spacer>
                <v-icon style="color:#ff6600;">fa fa-calendar</v-icon> <span
                    style="margin-right:10px;">{{getdate()}}</span>
            </v-layout>
            <br>


            <v-layout row wrap style="padding-right: 25px !important;">
                <v-flex md2 sm2 xs6  v-for="(item,i) in items.slice(0,6)" :key="item">

                    <p> {{item.item.name}}</p>
                    <v-layout column wrap>

                        <v-layout row wrap>
                            <v-card class="schedule_card">
                                <v-card-title>
                                    {{item.from_time}}
                                </v-card-title>
                                <v-card-text>
                                    {{item.item.sub_category.name}}
                                </v-card-text>
                                <v-btn dark :color="item.reservation_statuses.color" width="120px">
                                     <v-icon style="margin-left:10px;" color="#fff">{{item.reservation_statuses.icon}}</v-icon>
                                    {{item.reservation_statuses.name}}
                                   
                                </v-btn>
                            </v-card>
                            <v-icon style="color:gray; margin-right:250ypx;margin-left:40px ">fas fa-angle-left</v-icon>
                        </v-layout>

                        
                        <v-layout row wrap style="margin-top:20px;height:20px;">

                            <v-icon v-if="i==0" style="color:#464646;font-size:18px;">fas fa-clock</v-icon>
                            <v-icon v-else style="color:#464646;font-size:8px; margin:auto">fas fa-circle</v-icon>
                            <v-divider dark style="margin:auto;border-color:#464646; margin-left: 25px; "></v-divider>

                        </v-layout>

                    </v-layout>
                </v-flex>
               <v-flex>
                    <v-layout column wrap>
                        <br><br><br><br>

                        <span style="color:white;"> وقت الجدول الكلي </span>


                    </v-layout>

                    <br><br>


                </v-flex>
            </v-layout>

        </v-container>

    </v-layout>
    

    <v-layout v-else>

    </v-layout> -->

</div>
</template>
<script>
 //const axios = require('axios');
    export default {
        data() {
            return {
               
                urL: 'http://109.224.53.155',
                items: [],
              
                enable:false,
            }
        },






        methods: {
            getDiscription(disc, len) {
                var text = disc;

                text = text.substring(0, len)


                return text;
            },
            getitems() {
                

                //var url = + "/getUserReservations";
                 var url = "/reservation/user/search?filter[status_id]=5&page=1";
                this.$http({
                    method: 'get',
                    url: url,
                    headers: {
                       
                    }

                }).then(response => {
                    this.items =  response.data.data;
                           this.enable = true;

                    // this.alert_msg = false;
                    // this.loading = false;
                    // if (response.data.Data.data.length > 0) {
                    //     this.enable = true;
                    // }
                    // if (this.items.length > 9) {
                    //     this.items = this.items.slice(1, 7);
                    // }



                }).catch(error => {

               //  this.enable = false
                    this.msg = "يجب تسجيل الدخول"
                    this.alert_msg = true;
                    if (error.response.status == 402) {
                        //this.logout();
                        //this.$router.reload()
                    }

                });

            },
            getdate() {
                var date = new Date().toDateString();
                return date;
            }

        },


        created() {

           // this.getitems();


        },








    }
</script>